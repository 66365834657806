import styled from 'styled-components';

import theme from 'utils/theme';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OutOfStockSpan = styled.span`
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
  color: ${theme.colors.grey};
`;

export const SizeGuideRow = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: 15,
  marginBottom: -10,
  '> *': {
    marginRight: 10,
    marginBottom: 10,
  },
});
