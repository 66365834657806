import styled, { css } from 'styled-components';

import { OutOfStockSpan } from 'components/product/VariantSwitcher/styles';
import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface WrapperProps {
  disabled: boolean;
  variant: 'purple' | 'black';
  width?: 'auto' | 'full';
}

export const QuantityButton = styled.button`
  border: 0;
  background: transparent;
  margin: 0;
  color: inherit;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus-visible {
    color: ${theme.colors.black};
  }

  &:disabled {
    color: ${theme.colors.midGrey};
  }

  ${desktop} {
    padding: 5px 20px;
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  border-width: 2px;
  border-style: solid;
  border-color: ${({ variant }) =>
    variant === 'purple' ? theme.colors.purple : theme.colors.black};
  color: ${({ variant }) =>
    variant === 'purple' ? theme.colors.purple : theme.colors.black};
  background-color: ${({ variant }) =>
    variant === 'purple' ? 'transparent' : theme.colors.white};
  border-radius: 100px;
  font-weight: ${theme.weights.bold};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 46px;
  transition: color ${theme.transitionSpeeds.normal}s ease-in-out,
    border-color ${theme.transitionSpeeds.normal}s ease-in-out;

  width: 100px;
  max-width: 100%;

  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          background-color: ${theme.colors.lighterGrey};
          border-color: ${theme.colors.midGrey};
          color: ${theme.colors.midGrey};
        `
      : ''}

  ${({ width }) =>
    width === 'full'
      ? css`
          width: 100%;

          ${QuantityButton} {
            padding: 5px 15px;
          }
        `
      : ''}

  ${desktop} {
    width: 130px;
    min-height: ${theme.sizes.input.height};
  }
`;

export const Container = styled.div`
  ${desktop} {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

export const ValueLabel = styled.div`
  flex: 1;
  text-align: center;
`;

export const StockMessageSpan = styled(OutOfStockSpan)`
  display: block;
  text-align: center;
  margin-top: -22px;

  ${desktop} {
    margin-left: 10px;
    margin-top: 0;
  }
`;
