import React from 'react';

import MaskedIcon from 'components/MaskedIcon';
import { mobile, tablet } from 'utils/media';

import { NutshellRow, NutshellCol, NutshellIconWrapper } from './styles';
import { NutshellsProps } from './types';

export default function Nutshells({ nutshells }: NutshellsProps) {
  const slicedNutshells = nutshells.slice(0, 3);

  return (
    <NutshellRow>
      {slicedNutshells.map((nutshell) => {
        return (
          <NutshellCol key={nutshell._key}>
            {nutshell.icon?.icon?.icon ? (
              <NutshellIconWrapper>
                <MaskedIcon
                  asset={nutshell.icon.icon.icon}
                  size={{ [mobile]: 40, [tablet]: 40 }}
                />
              </NutshellIconWrapper>
            ) : null}
            {nutshell.icon?.name || nutshell.name}
          </NutshellCol>
        );
      })}
    </NutshellRow>
  );
}
